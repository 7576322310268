import React from "react";
import style from './index.module.less'
import { Layout, SEO } from '@/components/index'
import air2Logo from '@/images/air2/icon/banner-air2.svg'
const comingSoonBg = process.env.resourceUrl + 'images/coming-soon/cs-bg.jpg'
const Index = () => {

    return (
        <Layout footerBackground='#fff' footerTextWhite={false}
        >
            <SEO page="Coming Soon" />
            <div className={style.box} style={{ backgroundImage: `url(${comingSoonBg})` }}>
                {/* <img src={air2Logo} /> */}
                <div className={style.text}>
                   COMING SOON 
                </div>
                
            </div>
        </Layout>
    )
}

export default Index